//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server, imageUrl } from "@/services/constants";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialog from "@/components/cards/SuccessDialog";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import unity from "@/unity/unity";

export default {
    name: "approve-leave-confirm",
    components: { SuccessDialogPush, SuccessDialog,VueHtml2pdf },
    data(vm) {
        return {
            str_m_time: "",
            end_m_time: "",
            image_show: "",
            day_select: "multiple",
            leave_hours: [
            { text: "1 hour", value: 1 },
            { text: "2 hours", value: 2 },
            { text: "3 hours", value: 3 },
            { text: "Half day", value: 4 },
            { text: "5 hours", value: 5 },
            { text: "6 hours", value: 6 },
            { text: "7 hours", value: 7 },
            { text: "Full day", value: 8 },
            ],
            menu_start: false,
            menu_end: false,
            leave_start_hours: "",
            leave_end_hours: "",
            dialogConfirm: false,
            approve_end_flow_status: false,
            approve_detail_data: [],
            imageshowpopup: "",
            fullName: "",
            employee_id: "",
            dialogDelete: false,
            dialogSave: false,
            dialogReject: false,
            preview: null,
            image: null,
            preview_list: [],
            oldpreview_list: [],
            image_list: [],
            menu: false,
            menu2: false,
            search: "",
            toggleEnable: false,
            selectstatus: "",
            dropdownLeave: [],
            dataAll: [],
            mDataArray: [],
            amountShow: "",
            leave_data: {
            leaveType: 0,
            leaveTypeId: 0,
            approve_id_now: 0,
            startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            description: "",
            description_approve: "",
            status_approve_name: "",
            userId: 0,
            departmentId: 0,
            companyId: 0,
            amount: 0,
            images: null,
            lq_id: 0,
            },
            dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),

            dialogAdd: false,
            text_color: "text-h5 green--text text-center",
            title: "green",
            message: "green",
            link: "",
        };
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    computed: {
        computedDateFromFormatted() {
            return unity.formatDate(this.leave_data.startDate);
        },
        computedDateToFormatted() {
            return unity.formatDate(this.leave_data.endDate);
        },
    },
    async beforeCreate() {
        console.log("beforeCreate");
        this.$store.state.navMenu = true;
    },
    async mounted() {
        await api.checkVersion();
        this.$store.state.global_dialog_push = false;
        this.$store.state.global_dialog = false;

        this.userId = localStorage.getItem(server.USER_ID);

        await this.loadDataLeave();
        this.$hideLoader();
    },

    methods: {
        async loadDataLeave(){
            const res_leave = await api.getLeaveHistoryByIDWaiting( this.$route.params.id)
            console.log("res_leave.data: ")
            console.log(res_leave.data)

            this.leave_data.leaveTypeId =  res_leave.data[0].leave_name;
            this.day_select = res_leave.data[0].type_count;
            this.leave_data.startDate = res_leave.data[0].startDateS;
            this.leave_start_hours = res_leave.data[0].startHour;
            this.leave_data.endDate = res_leave.data[0].endDateS;
            this.leave_end_hours = res_leave.data[0].endHour;
            this.leave_data.description = res_leave.data[0].description;
            this.leave_data.description_approve = res_leave.data[0].description_approve;
            this.leave_data.status_approve_name = res_leave.data[0].approve_name;
            this.str_m_time = res_leave.data[0].t_startDateS;
            this.end_m_time = res_leave.data[0].T_endDateS;
            
            var amount = 0;
            var leave_day = 0;
            var leave_hours = 0;

            amount = Number(res_leave.data[0].amount)/60;
            leave_day = Math.floor(amount / 8);
            leave_hours = amount - (leave_day * 8);

            this.amountShow = `${leave_day} day ${leave_hours} hours`;

            let setiamggfile = res_leave.data[0].preview_image.split(",");
                if (setiamggfile[0] == "") {
                } else {
                    setiamggfile.forEach((x) => {
                        this.oldpreview_list.push(`${imageUrl}/${x}`);
                    });
                }
            
        },
        exportToPDF() {
            this.$refs.html2Pdf.generatePdf();
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },
        previewMultiImage: function(event) {
            var input = event.target;
            var count = input.files.length;
            var index = 0;
            if (input.files) {
            while (count--) {
                var reader = new FileReader();
                reader.onload = (e) => {
                this.preview_list.push(e.target.result);
                };
                if (input.files[index].type.split("/")[0] === "image") {
                this.image_list.push(input.files[index]);
                reader.readAsDataURL(input.files[index]);
                }
                index++;
            }
            }
            console.log(this.image_list);
        },
        deleteItemConfirm(imageurl) {
            // this.indeximage = index;
            this.imageshowpopup = imageurl;
            this.dialogDelete = true;
        },
    },
};
