import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import Register from "@/views/tranning/Register.vue";
import TranningList from "@/views/tranning/tranninglist.vue";
import TranningAdd from "@/views/tranning/tranningadd.vue";
import TranningEdit from "@/views/tranning/tranningedit.vue";
import Stock from "@/views/Stock.vue";
import StockCreate from "@/views/StockCreate.vue";
import StockEdit from "@/views/StockEdit.vue";
import Report from "@/views/Report.vue";
import About from "@/views/About.vue";
import Dashboard from "@/views/Dashboard.vue";
import Employee from "@/views/Employee.vue";
import Customer from "@/views/Customer.vue";
import Config from "@/views/Config.vue";
import RoleConfig from "@/views/RoleConfig.vue";
import GroupMenu from "@/views/GroupMenu.vue";
import SettingGroupMenu from "@/views/SettingGroupMenu.vue";
import MenuDetail from "@/views/MenuDetail.vue";
import SettingMenuDetail from "@/views/SettingMenuDetail.vue";
import SoList from "@/views/SoList.vue";
import SalesOutstanding from "@/views/SalesOutstanding.vue";
import SalesReport from "@/views/SalesReport.vue";
import AddSo from "@/views/AddSo.vue";
import Advancelist from "@/views/Advancelist.vue";
import NewAdvance from "@/views/NewAdvance.vue";
import EditAdvance from "@/views/EditAdvance.vue";
import ApproveAdvance from "@/views/ApproveAdvance.vue";
import ClearAdvancelist from "@/views/ClearAdvancelist.vue";
import ClearAdvanceadd from "@/views/ClearAdvanceAdd.vue";
import ApproveAdvancelist from "@/views/ApproveAdvancelist.vue";
import AccountPendingAdvancelist from "@/views/AccountPendingAdvancelist.vue";
import AccountClearAdvancelist from "@/views/AccountClearAdvancelist.vue";
import AccountReportingAdvancelist from "@/views/AccountReportingAdvancelist.vue";
import AccountPendingAdvance from "@/views/AccountPendingAdvance.vue";
import AccountClearAdvance from "@/views/AccountClearAdvance.vue";
import MyTimeSheet from "@/views/MyTimeSheet.vue";
import SummaryTimeActivityReport from "@/views/SummaryTimeActivityReport.vue";
import ApproveTimeSheet from "@/views/ApproveTimeSheet.vue";
import SummaryReport from "@/views/SummaryReport.vue";
import ClientSummaryReport from "@/views/ClientSummaryReport.vue";
import EmployeeSummaryReport from "@/views/EmployeeSummaryReport.vue";
import AttendantReport from "@/views/AttendantReport.vue";
import PInvoice from "@/views/invoice/PInvoice.vue";
import Invoice from "@/views/invoice/Invoice.vue";
import Receive from "@/views/invoice/Receive.vue";
import AdjustReceive from "@/views/invoice/AdjustReceive.vue";
import ReceiveSelectDocument from "@/views/invoice/ReceiveSelectDocument.vue";
import ReceiveConfirm from "@/views/invoice/ReceiveConfirm.vue";
import ReceiveEdit from "@/views/invoice/ReceiveEdit.vue";
import ReceiveAdjustEdit from "@/views/invoice/ReceiveAdjustEdit.vue";
import InvoiceReport from "@/views/invoice/InvoiceReport.vue";
import ReceiveReport from "@/views/invoice/ReceiveReport.vue";
import SOforInvoice from "@/views/invoice/SOforInvoice.vue";
import Newinvoice from "@/views/invoice/Newinvoice.vue";
import Newfreetextinvoice from "@/views/invoice/Newfreetextinvoice.vue";
import Editinvoice from "@/views/invoice/Editinvoice.vue";
import EditSo from "@/views/EditSo.vue";
import Company from "@/views/company.vue";
import Department from "@/views/department.vue";
import Position from "@/views/position.vue";
import Jobtitle from "@/views/jobtitle.vue";
import Myactivity from "@/views/myactivity.vue";
import NewActivity from "@/views/NewActivity.vue";
import LeaveQuota from "@/views/LeaveQuota.vue";
import invTitle from "@/views/INV_Title.vue";
import advanceTitle from "@/views/advanceTitle.vue";
import invTitleADD from "@/views/INV_Title_Add.vue";

import PettyCashVoucher from "@/views/pettyCashVoucher/pettyCashVoucher.vue";
import PettyCashOper from "@/views/pettyCashVoucher/PettyCashOper.vue";
import AddPettyCashVoucher from "@/views/pettyCashVoucher/AddPettyCashVoucher.vue";
import ApprovePettyCash from "@/views/pettyCashVoucher/ApprovePettyCash.vue";
import ApproveAccount from "@/views/pettyCashVoucher/ApproveAccount.vue";

import ApproverLeave from "@/views/leave/ApproverLeave.vue";
import LeaveHoliday from "@/views/leave/LeaveHoliday.vue";
import LeaveHolidayImport from "@/views/leave/leaveHolidayImport.vue";
import LeaveReport from "@/views/LeaveReport.vue";
import EditMyTimeSheet from "@/views/EditMyTimeSheet.vue";
import EditApproveTimeSheet from "@/views/EditApproveTimeSheet.vue";
import NewLeaveQuota from "@/views/NewLeaveQuota.vue";
import EditLeaveQuota from "@/views/EditLeaveQuota.vue";
import ImportLeaveQuota from "@/views/ImportLeaveQuota.vue";

import ApproveList from "@/views/leave/ApproveList.vue";
import ApproveLeaveConfirm from "@/views/leave/ApproveLeaveConfirm.vue";
import LeaveList from "@/views/leave/LeaveList.vue";
import LeaveTitle from "@/views/leave/leaveTitle.vue";
import LeaveRequest from "@/views/leave/LeaveRequest.vue";
import LeaveRequestEdit from "@/views/leave/LeaveRequestEdit.vue";
import LeaveRequestWaiting from "@/views/leave/LeaveRequestWaiting.vue";

import ResetPass from "@/views/accountManagement/ResetPass.vue";
import ForgotPass from "@/views/accountManagement/ForgotPass.vue";
import RegisterCompany from "@/views/accountManagement/RegisterCompany.vue";
import ForgotResetPass from "@/views/accountManagement/ForgotResetPass.vue";

import CreditNote from "@/views/invoice/CreditNote.vue";
import AddCreditNoteList from "@/views/invoice/AddCreditNoteList.vue";
import AddCreditNote from "@/views/invoice/AddCreditNote.vue";
import EditCreditNote from "@/views/invoice/EditCreditNote.vue";
import MiscellaneousList from "@/views/invoice/MiscellaneousList.vue";
import MiscellaneousListAdd from "@/views/invoice/MiscellaneousListAdd.vue";

import MasterList from "@/views/immigration/MasterList.vue";
import MasterAdd from "@/views/immigration/MasterAdd.vue";
import MasterEdit from "@/views/immigration/MasterEdit.vue";
import TransactionList from "@/views/immigration/TransactionList.vue";
import TransactionAdd from "@/views/immigration/TransactionAdd.vue";
import ImMasterPlaceofissueList from "@/views/immigration/ImMasterPlaceofissueList.vue";
import ImMasterPlaceofissueListAdd from "@/views/immigration/ImMasterPlaceofissueListAdd.vue";
import ImMasterWorkPlaceofissueList from "@/views/immigration/ImMasterWorkPlaceofissueList.vue";
import ImMasterWorkPlaceofissueListAdd from "@/views/immigration/ImMasterWorkPlaceofissueListAdd.vue";


import TransactionLogList from "@/views/loglist/TransactionLogList.vue";
import LogInOutLogList from "@/views/loglist/LogInOutLogList.vue";


import DocumentSetup from "@/views/DocumentSetup.vue";
import DocumentSetupAdd from "@/views/DocumentSetupAdd.vue";
import DocumentSetupAddTitle from "@/views/DocumentSetupAddTitle.vue";
import DocumentSetupEdit from "@/views/DocumentSetupEdit.vue";
import TransactionEdit from "@/views/immigration/TransactionEdit.vue";

import DDocument from "@/views/dDocument/ddocumentlist.vue";
import DDocumentAdd from "@/views/dDocument/ddocumentadd.vue";
import DDocumentEdit from "@/views/dDocument/ddocumentedit.vue";

import AdjustInvoiceList from "@/views/invoice/AdjustInvoiceList.vue";
import AdjustInvoice from "@/views/invoice/AdjustInvoice.vue";

///---------ip at law route ---------------------///

import MappingList from "@/views/ip-at-law-frontend/menuconfig/MappingList.vue";
import AddKeyWord from "@/views/ip-at-law-frontend/menuconfig/AddKeyword.vue";
import EditKeyWord from "@/views/ip-at-law-frontend/menuconfig/EditKeyword.vue";
import ShowKeyWord from "@/views/ip-at-law-frontend/menuconfig/ShowKeyword.vue";

import POA from "@/views/ip-at-law-frontend/contact/poa.vue";
import MasterTradeMarkList from "@/views/ip-at-law-frontend/mastertrademark/MasterTradeMarkList.vue";
import MasterTradeMarkAdd from "@/views/ip-at-law-frontend/mastertrademark/MasterTradeMarkAdd.vue";
import MasterTradeMarkEdit from "@/views/ip-at-law-frontend/mastertrademark/MasterTradeMarkEdit.vue";
import MasterPatentList from "@/views/ip-at-law-frontend/MasterPatentList/MasterPatentList.vue";
import MasterPatentAdd from "@/views/ip-at-law-frontend/MasterPatentList/MasterPatentAdd.vue";
import MasterPatentEdit from "@/views/ip-at-law-frontend/MasterPatentList/MasterPatentEdit.vue";

import AppointmentList from "@/views/ip-at-law-frontend/appointment/AppointmentList.vue";
import AppointmentAdd from "@/views/ip-at-law-frontend/appointment/AppointmentAdd.vue";
import AppointmentEdit from "@/views/ip-at-law-frontend/appointment/AppointmentEdit.vue";
import AppointmentImport from "@/views/ip-at-law-frontend/appointment/AppointmentImport.vue";

import AppointmentPatentList from "@/views/ip-at-law-frontend/appointmentPatent/AppointmentPatent.vue";
import AppointmentPatentOperation from "@/views/ip-at-law-frontend/appointmentPatent/AppointmentPatentOperation.vue";
import AppointmentPatentEdit from "@/views/ip-at-law-frontend/appointmentPatent/AppointmentPatentEdit.vue";
import AppointmentPatentImport from "@/views/ip-at-law-frontend/appointmentPatent/AppointmentpatentImport.vue";

import CoverReportTM from "@/views/ip-at-law-frontend/coverreporttm/CoverReportTM.vue";
import CoverReportPT from "@/views/ip-at-law-frontend/coverreportpt/CoverReportPT.vue";





// import pdfDraw from "@/views/ip-at-law-frontend/pdfManagement/pdfDraw.vue";
// import editPDF from "@/views/pdfManagement/editPDF.html";'

import ImportTrademark from "@/views/ip-at-law-frontend/import_ocr/ImportTrademark.vue";
import ImportPatent from "@/views/ip-at-law-frontend/import_ocr/ImportPatent.vue";

import ContactList from "@/views/ip-at-law-frontend/contact/ContactList.vue";

import IPPricelist from "@/views/IPPriceList/IPPricelist.vue";
import IPPriceAdd from "@/views/IPPriceList/IPPriceAdd.vue";

import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: ForgotResetPass,
  },
  {
    path: "/my-profile",
    name: "my-profile",
    component: ResetPass,
  },
  {
    path: "/approve-leave-confirm/:id/:employee_id",
    name: "approve-leave-confirm",
    component: ApproveLeaveConfirm,
  },
  {
    path: "/leave-holiday",
    name: "leave-holiday",
    component: LeaveHoliday,
  },
  {
    path: "/leave-holiday-import",
    name: "leave-holiday-import",
    component: LeaveHolidayImport,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPass,
  },
  {
    path: "/register-company",
    name: "Register Company",
    component: RegisterCompany,
  },
  {
    path: "/my-time-off",
    meta: {
      isSecured: false,
    },
    name: "My Time Off",
    component: LeaveList,
  },
  {
    path: "/approve-list",
    meta: {
      isSecured: false,
    },
    name: "approve-list",
    component: ApproveList,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  // {
  //   path: "/register/:tranning_id",
  //   name: "register",
  //   component: Register,
  // },
  {
    path: "/training-list",
    name: "TranningList",
    component: TranningList,
  },
  {
    path: "/training-add",
    name: "TranningAdd",
    component: TranningAdd,
  },
  {
    path: "/training-edit/:id",
    name: "TranningEdit",
    component: TranningEdit,
  },
  {
    path: "/stock",
    name: "stock",
    component: Stock,
  },
  {
    path: "/stock-create",
    name: "stock-create",
    component: StockCreate,
  },
  {
    path: "/stock-edit/:id",
    name: "stock-edit",
    component: StockEdit,
  },
  {
    path: "/report",
    meta: {
      isSecured: true,
    },
    name: "report",
    component: Report,
  },
  {
    path: "/about",
    meta: {
      isSecured: false,
    },
    name: "about",
    component: About,
  },
  {
    path: "/employee",
    meta: {
      isSecured: false,
    },
    name: "employee",
    component: Employee,
  },
  {
    path: "/customer",
    meta: {
      isSecured: false,
    },
    name: "customer",
    component: Customer,
  },
  {
    path: "/config",
    meta: {
      isSecured: false,
    },
    name: "config",
    component: Config,
  },
  {
    path: "/roleconfig",
    meta: {
      isSecured: false,
    },
    name: "roleconfig",
    component: RoleConfig,
  },
  {
    path: "/group-menu",
    meta: {
      isSecured: false,
    },
    name: "group-menu",
    component: GroupMenu,
  },
  {
    path: "/menu-detail",
    meta: {
      isSecured: false,
    },
    name: "menu-detail",
    component: MenuDetail,
  },
  {
    path: "/setting-group-menu",
    meta: {
      isSecured: false,
    },
    name: "setting-group-menu",
    component: SettingGroupMenu,
  },
  {
    path: "/setting-menu-detail/:id",
    meta: {
      isSecured: false,
    },
    name: "setting-menu-detail",
    component: SettingMenuDetail,
  },
  {
    path: "/so-list",
    meta: {
      isSecured: false,
    },
    name: "so-list",
    component: SoList,
  },
  {
    path: "/add-so",
    meta: {
      isSecured: false,
    },
    name: "add-so",
    component: AddSo,
  },
  {
    path: "/advance-list",
    meta: {
      isSecured: false,
    },
    name: "advance-list",
    component: Advancelist,
  },
  {
    path: "/new-advance",
    alias: ["/new-advance/:id"],
    meta: {
      isSecured: false,
    },
    name: "new-advance",
    component: NewAdvance,
  },
  {
    path: "/edit-advance/:id/:mode",
    alias: ["/edit-advance"],
    meta: {
      isSecured: false,
    },
    name: "edit-advance",
    component: EditAdvance,
  },
  {
    path: "/approve-advance/:id/:mode",
    alias: ["/approve-advance"],
    meta: {
      isSecured: false,
    },
    name: "approve-advance",
    component: ApproveAdvance,
  },
  {
    path: "/clearadvance-list",
    meta: {
      isSecured: false,
    },
    name: "clearadvance-list",
    component: ClearAdvancelist,
  },
  {
    path: "/clearadvance-add/:id",
    meta: {
      isSecured: false,
    },
    name: "clearadvance-add",
    component: ClearAdvanceadd,
  },
  {
    path: "/approve-advance-list",
    meta: {
      isSecured: false,
    },
    name: "approve-advance-list",
    component: ApproveAdvancelist,
  },
  {
    path: "/account-pending-advance-list",
    meta: {
      isSecured: false,
    },
    name: "account-pending-advance-list",
    component: AccountPendingAdvancelist,
  },
  {
    path: "/account-clear-advance-list",
    meta: {
      isSecured: false,
    },
    name: "account-clear-advance-list",
    component: AccountClearAdvancelist,
  },
  {
    path: "/account-reporting-advance-list",
    meta: {
      isSecured: false,
    },
    name: "account-reporting-advance-list",
    component: AccountReportingAdvancelist,
  },
  {
    path: "/account-pending-advance/:id",
    meta: {
      isSecured: false,
    },
    name: "account-pending-advance",
    component: AccountPendingAdvance,
  },
  {
    path: "/account-clear-advance/:id",
    meta: {
      isSecured: false,
    },
    name: "account-clear-advance",
    component: AccountClearAdvance,
  },
  {
    path: "/my-time-sheet",
    meta: {
      isSecured: false,
    },
    name: "my-time-sheet",
    component: MyTimeSheet,
  },
  {
    path: "/approve-time-sheet",
    meta: {
      isSecured: false,
    },
    name: "approve-time-sheet",
    component: ApproveTimeSheet,
  },
  {
    path: "/summary-timesheets-activity-report",
    meta: {
      isSecured: false,
    },
    name: "summary-timesheet-activity-Report",
    component: SummaryTimeActivityReport,
  },
  {
    path: "/activity-report",
    meta: {
      isSecured: false,
    },
    name: "activity-report",
    component: SummaryReport,
  },
  {
    path: "/client-timesheet-report",
    meta: {
      isSecured: false,
    },
    name: "Client Summary Report",
    component: ClientSummaryReport,
    children: [
      {
        path: ":timesheet_no",
        name: "Client Summary Report",
        props: true,
      },
    ],
  },
  {
    path: "/employee-timesheet-report",
    meta: {
      isSecured: false,
    },
    name: "Employee Summary Report",
    component: EmployeeSummaryReport,
  },
  {
    path: "/attendant-report",
    meta: {
      isSecured: false,
    },
    name: "Attendant Report",
    component: AttendantReport,
  },
  {
    path: "/edit-so/:id",
    meta: {
      isSecured: false,
    },
    name: "edit-so",
    component: EditSo,
  },
  {
    path: "/invoice",
    meta: {
      isSecured: false,
    },
    name: "invoice",
    component: Invoice,
  },
  {
    path: "/adjust-invoice",
    meta: {
      isSecured: false,
    },
    name: "Adjust Invoice List",
    component: AdjustInvoiceList,
  },
  {
    path: "/adjust-invoice-edit/:id",
    meta: {
      isSecured: false,
    },
    name: "Adjust Invoice",
    component: AdjustInvoice,
  },
  
  {
    path: "/receive",
    meta: {
      isSecured: false,
    },
    name: "receive",
    component: Receive,
  },
  {
    path: "/adjust-receipt",
    meta: {
      isSecured: false,
    },
    name: "AdjustReceive",
    component: AdjustReceive,
  },
  {
    path: "/receive-select-document/:company_inv",
    meta: {
      isSecured: false,
    },
    name: "Receive Select Document",
    component: ReceiveSelectDocument,
  },
  {
    path: "/receive-edit/:id",
    meta: {
      isSecured: false,
    },
    name: "Receive Edit",
    component: ReceiveEdit,
  },
  {
    path: "/receive-adjsut-edit/:id",
    meta: {
      isSecured: false,
    },
    name: "Receive Edit",
    component: ReceiveAdjustEdit,
  },
  {
    path: "/receive-confirm/:company_inv/:customer_id/:id",
    meta: {
      isSecured: false,
    },
    name: "Receive Confirm",
    component: ReceiveConfirm,
  },
  {
    path: "/invoice-report",
    meta: {
      isSecured: false,
    },
    name: "invoice-report",
    component: InvoiceReport,
  },
  {
    path: "/receipt-report",
    meta: {
      isSecured: false,
    },
    name: "receipt-report",
    component: ReceiveReport,
  },
  {
    path: "/sales-outstanding",
    meta: {
      isSecured: false,
    },
    name: "sales-outstanding",
    component: SalesOutstanding,
  },
  {
    path: "/sales-report",
    meta: {
      isSecured: false,
    },
    name: "sales-report",
    component: SalesReport,
  },
  {
    path: "/company",
    meta: {
      isSecured: false,
    },
    name: "company",
    component: Company,
  },
  {
    path: "/department",
    meta: {
      isSecured: false,
    },
    name: "department",
    component: Department,
  },
  {
    path: "/position",
    meta: {
      isSecured: false,
    },
    name: "position",
    component: Position,
  },
  {
    path: "/jobtitle",
    meta: {
      isSecured: false,
    },
    name: "jobtitle",
    component: Jobtitle,
  },
  {
    path: "/my-activity",
    meta: {
      isSecured: false,
    },
    name: "My Activity",
    component: Myactivity,
  },
  {
    path: "/new-activity",
    meta: {
      isSecured: false,
    },
    name: "New Activity",
    component: NewActivity,
  },
  {
    path: "/leave-quota",
    meta: {
      isSecured: false,
    },
    name: "Leave Quota",
    component: LeaveQuota,
  },
  {
    path: "/document-setup",
    meta: {
      isSecured: false,
    },
    name: "Document Setup",
    component: DocumentSetup,
  },
  {
    path: "/document-setup-add",
    meta: {
      isSecured: false,
    },
    name: "Document Setup Add",
    component: DocumentSetupAdd,
  },
  {
    path: "/document-setup-title/:mode/:doctype_id",
    meta: {
      isSecured: false,
    },
    name: "Document Setup Add Title",
    component: DocumentSetupAddTitle,
  },
  {
    path: "/document-setup-edit/:doctype_id",
    meta: {
      isSecured: false,
    },
    name: "Document Setup Edit",
    component: DocumentSetupEdit,
  },
  {
    path: "/leave-request",
    meta: {
      isSecured: false,
    },
    name: "Leave Request",
    component: LeaveRequest,
  },
  {
    path: "/leave-request-edit/:id",
    meta: {
      isSecured: false,
    },
    name: "Leave Request Edit",
    component: LeaveRequestEdit,
  },
  {
    path: "/leave-request-waiting/:id",
    meta: {
      isSecured: false,
    },
    name: "Leave Request Edit",
    component: LeaveRequestWaiting,
  },
  {
    path: "/approver-time-off",
    meta: {
      isSecured: false,
    },
    name: "Approve Time Off",
    component: ApproverLeave,
  },
  {
    path: "/leave-report",
    meta: {
      isSecured: false,
    },
    name: "Leave Report",
    component: LeaveReport,
  },
  {
    path: "/edit-mytimesheet/:id",
    meta: {
      isSecured: false,
    },
    name: "Edit My TimeSheet",
    component: EditMyTimeSheet,
  },
  {
    path: "/edit-approvetimesheet/:id",
    meta: {
      isSecured: false,
    },
    name: "Edit Approve TimeSheet",
    component: EditApproveTimeSheet,
  },
  {
    path: "/new-leave-quota",
    meta: {
      isSecured: false,
    },
    name: "New Leave Quota",
    component: NewLeaveQuota,
  },
  {
    path: "/edit-leave-quota/:id/:period_datefrom/:period_dateto",
    meta: {
      isSecured: false,
    },
    name: "Edit Leave Quota",
    component: EditLeaveQuota,
  },
  {
    path: "/import-leave-quota",
    meta: {
      isSecured: false,
    },
    name: "Import Leave Quota",
    component: ImportLeaveQuota,
  },
  {
    path: "/invoice-title",
    meta: {
      isSecured: false,
    },
    name: "Invoice Title",
    component: invTitle,
  },
  {
    path: "/advance-title",
    meta: {
      isSecured: false,
    },
    name: "Advance Title",
    component: advanceTitle,
  },
  {
    path: "/invioce-title-add/:mode/:id",
    meta: {
      isSecured: false,
    },
    name: "Invoice Title Add",
    component: invTitleADD,
  },
  {
    path: "/leave-title",
    meta: {
      isSecured: false,
    },
    name: "Leave Title",
    component: LeaveTitle,
  },
  {
    path: "/so-for-invoice/:company_inv",
    meta: {
      isSecured: false,
    },
    name: "SalseOrder For Invoice",
    component: SOforInvoice,
  },
  {
    path: "/new-free-invoice/:id/:mode/:company_inv",
    meta: {
      isSecured: false,
    },
    children: [
      {
        path: ":customer_id/:menurequest",
        name: "New Invoice Free Text",
        props: true,
      },
    ],
    name: "New Invoice",
    component: Newfreetextinvoice,
  },
  {
    path: "/new-invoice/:id/:mode/:company_inv",
    meta: {
      isSecured: false,
    },
    name: "New Invoice",
    component: Newinvoice,
  },
  {
    path: "/edit-invoice/:id/:mode/:company_inv",
    meta: {
      isSecured: false,
    },
    name: "Edit Invoice",
    component: Editinvoice,
  },
  {
    path: "/credit-note",
    meta: {
      isSecured: false,
    },
    name: "Credit Note",
    component: CreditNote,
  },
  {
    path: "/add-credit-note-list",
    meta: {
      isSecured: false,
    },
    name: "Add CreditNote List",
    component: AddCreditNoteList,
  },
  {
    path: "/add-credit-note/:id",
    meta: {
      isSecured: false,
    },
    name: "Add CreditNote",
    component: AddCreditNote,
  },
  {
    path: "/edit-credit-note/:id",
    meta: {
      isSecured: false,
    },
    name: "Edit CreditNote",
    component: EditCreditNote,
  },
  {
    path: "/immigration-master",
    meta: {
      isSecured: false,
    },
    name: "Master List",
    component: MasterList,
  },
  {
    path: "/transaction-log-list",
    meta: {
      isSecured: false,
    },
    name: "Transaction Log List",
    component: TransactionLogList,
  },
  {
    path: "/log-in-out-log-list",
    meta: {
      isSecured: false,
    },
    name: "Log in out Log List",
    component: LogInOutLogList,
  },
  {
    path: "/immigration-master-add",
    meta: {
      isSecured: false,
    },
    name: "Master Add",
    component: MasterAdd,
  },
  {
    path: "/immigration-transaction-add/",
    meta: {
      isSecured: false,
    },
    name: "Transaction Add",
    component: TransactionAdd,
    children: [
      {
        path: ":file_no",
        name: "AppointmentAdd",
        props: true,
      },
    ],
  },
  {
    path: "/immigration-master-edit/:id",
    meta: {
      isSecured: false,
    },
    name: "Master Edit",
    component: MasterEdit,
  },
  {
    path: "/immigration-transaction",
    meta: {
      isSecured: false,
    },
    name: "Transaction List",
    component: TransactionList,
  },
  {
    path: "/immigration-transaction-edit/:id",
    meta: {
      isSecured: false,
    },
    name: "Immigration Transaction Edit",
    component:TransactionEdit,
  },
  {
    path: "/d-document",
    meta: {
      isSecured: false,
    },
    name: "D-Document",
    component:DDocument,
    children: [
      {
        path: ":id",
        name: "D-Document",
        props: true,
      },
    ],
  },
  {
    path: "/d-document-add/:mode/:doctype_id",
    meta: {
      isSecured: false,
    },
    name: "D-Document Add",
    component:DDocumentAdd,
  },
  {
    path: "/d-document-operation/:mode/:doctype_id/:doctrans_hid",
    meta: {
      isSecured: false,
    },
    name: "D-Document Edit",
    component:DDocumentEdit,
  },
  {
    path: "/miscellaneous-list",
    meta: {
      isSecured: false,
    },
    name: "Miscellaneous List",
    component: MiscellaneousList,
  },
  {
    path: "/miscellaneous-list-add/:mode/:id",
    meta: {
      isSecured: false,
    },
    name: "Miscellaneous List Add",
    component: MiscellaneousListAdd,
  },
  {
    path: "/im-master-placeofissue-list",
    meta: {
      isSecured: false,
    },
    name: "Immigration Master Place of issue List",
    component: ImMasterPlaceofissueList,
  },
  {
    path: "/im-master-placeofissue-list-add/:mode/:id",
    meta: {
      isSecured: false,
    },
    name: "Immigration Master Place of issue List Add",
    component: ImMasterPlaceofissueListAdd,
  },
  {
    path: "/im-master-workplaceofissue-list",
    meta: {
      isSecured: false,
    },
    name: "Immigration Master Work Place of issue List",
    component: ImMasterWorkPlaceofissueList,
  },
  {
    path: "/im-master-workplaceofissue-list-add/:mode/:id",
    meta: {
      isSecured: false,
    },
    name: "Immigration Master Work Place of issue List Add",
    component: ImMasterWorkPlaceofissueListAdd,
  },

  
  ////-------------- ip at law router-------------------////
  {
    path: "/import-tm",
    name: "import-tm",
    component: ImportTrademark,
  },
  {
    path: "/import-pt",
    name: "import-pt",
    component: ImportPatent,
  },
  // {
  //   path: "/pdfDraw",
  //   name: "pdfDraw",
  //   component: pdfDraw,
  // },
  {
    path: "/mapping-keyword",
    meta: {
      isSecured: false,
    },
    name: "mapping-keyword",
    component: MappingList,
  },
  {
    path: "/new-keyword",
    meta: {
      isSecured: false,
    },
    name: "new-keyword",
    component: AddKeyWord,
  },
  {
    path: "/edit-keyword/:mode/:doctype_id/:notice_id",
    meta: {
      isSecured: false,
    },
    name: "edit-keyword",
    component: EditKeyWord,
  },
  {
    path: "/show-keyword/:mode/:doctype_id/:notice_id",
    meta: {
      isSecured: false,
    },
    name: "show-keyword",
    component: ShowKeyWord,
  },
  {
    path: "/contact-list",
    meta: {
      isSecured: false,
    },
    name: "ContactList",
    component: ContactList,
  },
  {
    path: "/ip-price-list",
    meta: {
      isSecured: false,
    },
    name: "IP Price List",
    component: IPPricelist,
  },
  {
    path: "/ip-price-add",
    meta: {
      isSecured: false,
    },
    name: "IP Price Add",
    component: IPPriceAdd,
  },
  {
    path: "/ip-price-edit/:id",
    meta: {
      isSecured: false,
    },
    name: "IP Price Add",
    component: IPPriceAdd,
  },
  {
    path: "/poa/:id",
    meta: {
      isSecured: false,
    },
    name: "POA",
    component: POA,
  },
  {
    path: "/master-trademark-list",
    meta: {
      isSecured: false,
    },
    name: "MasterTradeMarkList",
    component: MasterTradeMarkList,
  },
  {
    path: "/master-patent-list",
    meta: {
      isSecured: false,
    },
    name: "MasterPatentList",
    component: MasterPatentList,
  },
  {
    path: "/master-patent-add",
    meta: {
      isSecured: false,
    },
    name: "MasterPatentAdd",
    component: MasterPatentAdd,
  },
  {
    path: "/master-patent-edit/:masterpt_id",
    meta: {
      isSecured: false,
    },
    name: "MasterPatentEdit",
    component: MasterPatentEdit,
  },
  {
    path: "/appointment-patent-list",
    meta: {
      isSecured: false,
    },
    name: "AppointmentPatentList",
    component: AppointmentPatentList,
  },
  {
    path: "/appointment-patent-add",
    meta: {
      isSecured: false,
    },
    name: "AppointmentPatentAdd",
    component: AppointmentPatentOperation,
    children: [
      {
        path: ":file_no",
        name: "AppointmentPatentAdd",
        props: true,
      },
    ],
  },
  {
    path: "/appointment-patent-edit/:appointment_id",
    meta: {
      isSecured: false,
    },
    name: "AppointmentPatentEdit",
    component: AppointmentPatentEdit,
  },
  {
    path: "/appointment-patent-import",
    meta: {
      isSecured: false,
    },
    name: "AppointmentPatentImport",
    component: AppointmentPatentImport,
  },
  {
    path: "/appointment-trademark-list",
    meta: {
      isSecured: false,
    },
    name: "AppointmentList",
    component: AppointmentList,
  },
  {
    path: "/master-trademark-add",
    meta: {
      isSecured: false,
    },
    name: "MasterTradeMarkAdd",
    component: MasterTradeMarkAdd,
  },
  {
    path: "/master-trademark-edit/:mastertm_id",
    meta: {
      isSecured: false,
    },
    name: "MasterTradeMarkEdit",
    component: MasterTradeMarkEdit,
  },
  {
    path: "/appointmenttm-add/",
    meta: {
      isSecured: false,
    },
    name: "AppointmentAdd",
    component: AppointmentAdd,
    children: [
      {
        path: ":file_no/:date_of_renewal",
        name: "AppointmentAdd",
        props: true,
      },
    ],
  },
  {
    path: "/appointmenttm-edit/:appointment_id",
    meta: {
      isSecured: false,
    },
    name: "AppointmentEdit",
    component: AppointmentEdit,
  },
  {
    path: "/appointment-import",
    meta: {
      isSecured: false,
    },
    name: "AppointmentImport",
    component: AppointmentImport,
  },
  {
    path: "/cover-report-tm",
    meta: {
      isSecured: false,
    },
    name: "CoverReportTM",
    component: CoverReportTM,
  },
  {
    path: "/cover-report-pt",
    meta: {
      isSecured: false,
    },
    name: "CoverReportPT",
    component: CoverReportPT,
  },
  {
    path: "/petty-cash-voucher",
    meta: {
      isSecured: false,
    },
    name: "PettyCashVoucher",
    component: PettyCashVoucher,
  },
  {
    path: "/petty-cash/:mode/:id",
    meta: {
      isSecured: false,
    },
    name: "PettyCashOper",
    component: PettyCashOper,
  },
  {
    path: "/add-petty-cash",
    meta: {
      isSecured: false,
    },
    name: "AddPettyCashVoucher",
    component: AddPettyCashVoucher,
  },
  {
    path: "/approve-petty-cash",
    meta: {
      isSecured: false,
    },
    name: "ApprovePettyCash",
    component: ApprovePettyCash,
  },
  {
    path: "/approve-account-petty-cash",
    meta: {
      isSecured: false,
    },
    name: "ApproveAccount",
    component: ApproveAccount,
  },
  //-----------------------------end-----------------//
  {
    path: "*",
    redirect: "/dashboard",
  },
];

Vue.use(VueLoading, {
  color: "green",
  loader: "dots",
  canCancel: false,
  width: 120,
  height: 120,
  backgroundColor: "#ffffff",
  opacity: 0.5,
  zIndex: 999,
});
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // history: VueRouter.createWebHashHistory(),
  routes,
});

let loader = null;

function hideLoader() {
  // destroy previous
  if (loader) {
    loader.hide();
    loader = null;
  }
}

function showLoader() {
  hideLoader();
  loader = Vue.$loading.show();
}
// Vue.prototype.$showLoader = showLoader
// Vue.prototype.$hideLoader = hideLoader
router.beforeEach((to, from, next) => {
  if (to.name) {
    showLoader();
  }
  Vue.prototype.$showLoader = showLoader;
  Vue.prototype.$hideLoader = hideLoader;
  next();
});
router.afterEach((to, from) => {
  showLoader();
  // setTimeout(() => {
  //   loader.hide()
  // }, 500)
});
export default router;
