//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server, imageUrl } from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import unity from "@/unity/unity";

export default {
  name: "leave-request",
  components: { SuccessDialog, SuccessDialogPush, DateTimePicker },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  data(vm) {
    return {
      check_cross_per: "",
      day_select: "",
      select_leave_id: 0,
      user_approve_all: [],
      user_approve_count_status: false,
      user_approve_count_status2: false,
      date_start: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date_end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu_start: false,
      menu_end: false,
      leave_start_hours: "",
      leave_end_hours: "",
      leave_start_min: "",
      leave_end_min: "",
      leave_hours: [
        { text: "1 hour", value: 1 },
        { text: "2 hours", value: 2 },
        { text: "3 hours", value: 3 },
        { text: "Half day", value: 4 },
        { text: "5 hours", value: 5 },
        { text: "6 hours", value: 6 },
        { text: "7 hours", value: 7 },
        { text: "Full day", value: 8 },
      ],
      leave_min: [
        { text: "00", value: "00" },
        { text: "30", value: "30" },
      ],
      startDate: new Date(),
      endDate: new Date(),
      dialogDelete: false,
      dialogSave: false,
      dialogCancel: false,
      preview: null,
      image: null,
      preview_list: [],
      oldpreview_list: [],
      image_list: [],
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dropdownLeave: [],
      dataAll: [],
      delete_his_id: 0,
      delete_lq_id: [],
      delete_amount: 0,
      update_delete_req: {},
      headers: [
        {
          text: "Period",
          value: "period",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Leave Type",
          value: "leave_type",
          class: "bg-colorth tabletextwhite",
        },
        // {
        //   text: "Quota (Day)",
        //   value: "quota_day_show",
        //   class: "bg-colorth tabletextwhite",
        // },
        // {
        //   text: "Used",
        //   value: "user_day_show",
        //   class: "bg-colorth tabletextwhite",
        // },
        {
          text: "Quota (Day)",
          value: "balance_show",
          class: "bg-colorth tabletextwhite",
        },
      ],
      leave_data: {},
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
    };
  },

  computed: {
    computedDateFromFormatted() {
      return unity.formatDate(this.date_start);
    },
    computedDateToFormatted() {
      return unity.formatDate(this.date_end);
    },
    allowedDates() {
      return (date) => {
        date = new Date(date)
        const dayOfWeek = date.getDay();
        return dayOfWeek !== 0 && dayOfWeek !== 6; // Disable Sunday (0) and Saturday (6)
      };
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async mounted() {
    // this.$store.state.global_dialog_push = false;
    // this.$store.state.global_dialog = false;

    console.log("mounted");
    this.userId = localStorage.getItem(server.USER_ID);
    console.log("mounted2222");
    console.log("userId:" + this.userId);

    //เช็คว่ามีคน Approve หรือไม่
    await this.loadLevelApprove(this.userId);
    await this.loadLeaveHistory(this.$route.params.id);
    await this.loaddataLeaveUsed(this.userId);

    this.$hideLoader();
  },

  methods: {
    async handleRadioDaySelect() {
      if(this.day_select == "single"){
        this.leave_end_hours == "";
      }else if(this.day_select == "multiple"){
        this.leave_end_hours == "";
      }else{
        return;
      }
    },
    async loadLeaveHistory(his_id) {
      const res = await api.getLeaveHistoryByHistoryID(his_id);
      console.log("res.data")
      console.log(res.data)
      if (res.data.length <= 0) {
        this.user_approve_count_status2 = false;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "No Time Off",
          "You do not have any time off permitted.",
          "text-h5 red--text text-center"
        );
        return;
      } 
      
      if(res.data.length > 1){
        this.check_cross_per = "cross_period"
      }else if(res.data.length == 1){
        this.check_cross_per = "single_period"
      }else{
        return;
      }

      this.leave_data.leaveType = res.data[0].leaveType;
      this.leave_data.leaveTypeId = res.data[0].leaveTypeId;
      this.day_select = res.data[0].type_count;
      this.leave_data.type_count = res.data[0].type_count;

      this.leave_data.startDate = res.data[0].startDate;
      this.leave_data.endDate = res.data[0].endDate;

      this.date_start = new Date(res.data[0].startDate).toISOString().substr(0, 10);
      this.date_end = new Date(res.data[0].endDate).toISOString().substr(0, 10);
      this.leave_start_hours = res.data[0].startHour;

      if(res.data[0].type_count == "single"){
        this.leave_end_hours = 0;
      }else if(res.data[0].type_count == "multiple"){
        this.leave_end_hours = res.data[0].endHour;
      }else{
        return;
      }
        
      this.leave_data.description = res.data[0].description;
      this.leave_data.userId = res.data[0].userId;
      this.leave_data.departmentId = res.data[0].departmentId;
      this.leave_data.companyId = res.data[0].companyId;


      //start load image
      this.leave_data.images = res.data[0].images;
      if (res.data[0].images == "" || res.data[0].images == null) {
        this.leave_data.images = imageUrl + "/personal.png";
      } else {
        this.leave_data.images = imageUrl + "/" + res.data[0].images;
      }
      if(res.data[0].images != ""){
        let setiamggfile = res.data[0].images.split(",");
        setiamggfile.forEach((x) => {
          this.oldpreview_list.push(`${imageUrl}/${x}`);
        });
      }
      //end load image

      if(this.check_cross_per == "single_period"){
        this.leave_data.old_amount = res.data[0].amount_detail;
        this.leave_data.old_lq_id = res.data[0].lq_id_detail;
        this.delete_lq_id[0] = {old_lq_id: res.data[0].lq_id_detail};
      }else if(this.check_cross_per == "cross_period"){
        var data_cross_per = [];
        res.data.forEach((item,index) => {
          var data = {};
          data.old_amount = item.amount_detail;
          data.old_lq_id = item.lq_id_detail;

          data_cross_per.push(data);
        })
        console.log("data_cross_per")
        console.log(data_cross_per)
        this.leave_data.old_amount_list = data_cross_per;
        this.delete_lq_id = data_cross_per;
      }else{
        return;
      }
    },

    async loaddataLeaveUsed(id) {

      const result = await api.getListUseByUserIDV2byCompany(id,localStorage.getItem(server.COMPANYID));

      console.log("this.leave_data");
      console.log(this.leave_data);
      console.log(result.data);

      
      if (result.data.length <= 0) {
        this.user_approve_count_status2 = false;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "No Time Off",
          "You do not have any time off permitted.",
          "text-h5 red--text text-center"
        );
        return;
      } else {
        this.user_approve_count_status2 = true;
      }

      if(this.check_cross_per == "single_period"){
        // alert("test")
        var data = [];

        result.data.forEach((item) => {

            var obj = {};

            if (item.id == this.leave_data.old_lq_id) {
                item.use_leave = item.use_leave - (Number(this.leave_data.old_amount) / 60 / 8);
                item.balance = Number(item.balance) + (Number(this.leave_data.old_amount) / 60 / 8);
                obj.use_leave = item.use_leave;
                obj.balance = item.balance;

            } else {
              obj.use_leave = item.use_leave;
              obj.balance = item.balance;
            }
            obj.id = item.id;
            obj.leave_type_id = item.leave_type_id;
            obj.leave_period_from = item.leave_period_from;
            obj.leave_period_to = item.leave_period_to;
            obj.period = item.period;
            obj.leave_type = item.leave_type;
            obj.user_day_show = this.quotaListConvert(item.use_leave);
            obj.balance_show = this.quotaListConvert(item.balance);
            obj.quota_day_show = this.quotaListConvert(item.quota_day);
            obj.user_day = item.user_day;
            obj.quota_day = item.quota_day;
            data.push(obj);
        });

        this.dataAll = data;
        
        // console.log( "this.dataAll")
        // console.log( this.dataAll)

      }else if(this.check_cross_per == "cross_period"){

        var data = [];

        var data_cross_qouta = this.leave_data.old_amount_list;
        console.log(data_cross_qouta)

        result.data.forEach((item) => {

            var obj = {};
            var check_leave = 0;

            data_cross_qouta.forEach( (items) => {
              if (item.id == items.old_lq_id) {

                check_leave = 1;

                item.use_leave = item.use_leave - (Number(items.old_amount) / 60 / 8);
                item.balance = Number(item.balance) + (Number(items.old_amount) / 60 / 8);
                obj.use_leave = item.use_leave;
                obj.balance = item.balance;

              }else{
                return;
              }
            })

            if(check_leave == 0){
              obj.use_leave = item.use_leave;
              obj.balance = item.balance;
            }

            obj.id = item.id;
            obj.leave_type_id = item.leave_type_id;
            obj.leave_period_from = item.leave_period_from;
            obj.leave_period_to = item.leave_period_to;
            obj.period = item.period;
            obj.leave_type = item.leave_type;
            obj.user_day_show = this.quotaListConvert(item.use_leave);
            obj.balance_show = this.quotaListConvert(item.balance);
            obj.quota_day_show = this.quotaListConvert(item.quota_day);
            obj.user_day = item.user_day;
            obj.quota_day = item.quota_day;
            data.push(obj);

        });

        this.dataAll = data;
      }else{
        return;
      }

      //load list leave
      const result_dropsown = await api.getListByUserIDV2byCompanyforEdit(
        id,
        localStorage.getItem(server.COMPANYID)
      );
      this.dropdownLeave = result_dropsown.data;

    },
    async checkHoliday(date,mode){
        // alert(date)
        const res_date = await api.getLeaveHolidayByDate(date,Number(localStorage.getItem(server.COMPANYID)))
        console.log("res_date.data")
        console.log(res_date.data)
        if(res_date.data.length > 0){

          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            `ไม่สามารถเลือกได้เนื่องจาก ${unity.formatDate(date)} เป็นวันหยุด ${res_date.data[0].h_name}`,
            "text-h5 red--text text-center"
          );
          return;
        }
    },
    async loadLevelApprove(userId) {

      const res_acc = await api.getAccountid(userId);
      // console.log("res_acc", JSON.stringify(res_acc.data));

      if (
        res_acc.data[0].approver_level1 !== null ||
        res_acc.data[0].approver_level1 !== ""
      ) {

        this.user_approve_count_status = true;
        this.user_approve_all.push(res_acc.data[0].approver_level1);

      } else {

        this.user_approve_count_status = false;

        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ไม่มีผู้อนุมัติ",
          "คุณไม่มีผู้อนุมัติ กรุณาเพิ่มผู้อนุมัติด้วย",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (
        res_acc.data[0].approver_level2 !== null ||
        res_acc.data[0].approver_level2 !== ""
      ) {
        this.user_approve_count_status = true;
        this.user_approve_all.push(res_acc.data[0].approver_level2);
      }

      if (
        res_acc.data[0].approver_level3 !== null ||
        res_acc.data[0].approver_level3 !== ""
      ) {
        this.user_approve_count_status = true;
        this.user_approve_all.push(res_acc.data[0].approver_level3);
      }
    },

    checkDateFormat(str_date) {
      var r_date = "";

      if (str_date.length == 1) {
        r_date = `0${str_date}`;
      } else {
        r_date = str_date;
      }
      return r_date;
    },



    async onSave() {

      this.dialogSave = false;
      
      //validate ข้อมูล
      if (this.leave_data.leaveTypeId == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกประเภทการลา",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        this.leave_start_hours === null ||
        this.leave_start_hours === "" ||
        this.leave_start_hours === 0
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "Please select a time period ",
          "text-h5 red--text text-center"
        );
        return;
      }

      const res_date_start = await api.getLeaveHolidayByDate(this.date_start,Number(localStorage.getItem(server.COMPANYID)))
      if(res_date_start.data.length > 0){
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          `ไม่สามารถเลือกได้เนื่องจาก ${unity.formatDate(this.date_start)} เป็นวันหยุด ${res_date_start.data[0].h_name}`,
          "text-h5 red--text text-center"
        );
        return;
      }

      if(this.day_select == "multiple"){

        if (this.date_start >=  this.date_end) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "คุณเลือกช่วงเวลาไม่ถูกต้อง",
            "text-h5 red--text text-center"
          );
          return;
        }

        const res_date_end = await api.getLeaveHolidayByDate(this.date_end,Number(localStorage.getItem(server.COMPANYID)))
        if(res_date_end.data.length > 0){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            `ไม่สามารถเลือกได้เนื่องจาก ${unity.formatDate(this.date_end)} เป็นวันหยุด ${res_date_end.data[0].h_name}`,
            "text-h5 red--text text-center"
          );
          return;
        }

        if (
          this.leave_end_hours === null ||
          this.leave_end_hours === "" ||
          this.leave_end_hours === 0
        ) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "Please select a time period ",
            "text-h5 red--text text-center"
          );
          return;
        }
      }

       //รวมเวลา start end
      if(this.day_select == "single"){
        this.leave_data.startDate = this.date_start + " 08:30";
        this.leave_data.endDate = this.date_start + " 17:30";
      }else if(this.day_select == "multiple"){
        
        this.leave_data.startDate = this.date_start + " 08:30";
        this.leave_data.endDate = this.date_end + " 17:30";
      }else{
        return;
      }
      //end validate ข้อมูล

      //เช็ค เวลาตรงกับช่วงเวลาที่มีหรือไหม
      let res_chk_period = []
      if(this.day_select == "single"){
        res_chk_period = await api.getAllCheckPeriodByLeaveType(
          localStorage.getItem(server.USER_ID),
          this.date_start,
          this.date_start,
          this.leave_data.leaveTypeId
        );
      }else if(this.day_select == "multiple"){
        res_chk_period = await api.getAllCheckPeriodByLeaveType(
          localStorage.getItem(server.USER_ID),
          this.date_start,
          this.date_end,
          this.leave_data.leaveTypeId
        );
      }else{
        return;
      }

      console.log("res_chk_period")
      console.log(res_chk_period.data.length)
      if (res_chk_period.data.length == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "วันลา ไม่อยู่ในช่วงที่กำหนด",
          "text-h5 red--text text-center"
        );
        return;
      }
      //end การเช็คช่วงเวลา

      let period_data = [];
      period_data = res_chk_period.data;


      const millisecondsInOneDay = 24 * 60 * 60 * 1000;


        // let startDate = new Date(this.date_start);
        // let endDate = new Date(this.date_end);
        
      console.log(period_data)

      let sum_avg_minutes = 0;
      let avg_minutes_list = [];
      let leave_quota_list = "";

      if(this.day_select == "single"){
        //start คำนวณวันลา
        let avg_minutes = 0; //เวลาที่ลา เป็นนาที
        avg_minutes = Number(this.leave_start_hours) * 60;
        //end คำนวณวันลา

        // //start เช็คโคต้าวันลา   
        var quota_amt = 0; //โควต้าวันลาเป็นนาที
        
        this.dataAll.forEach((item) => {
          if (item.id == period_data[0].leave_quota_id) {
            this.leave_data.lq_id_detail = period_data[0].leave_quota_id;
            quota_amt = item.balance * 480;
          }
        });
        console.log("leave single day")
        console.log(quota_amt)
        console.log(avg_minutes)
        if (avg_minutes > quota_amt) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "ลาเกิน",
            "คุณลาเกินวันลาที่มี",
            "text-h5 red--text text-center"
          );
          return;
        }
        //end เช็คโคต้าวันลา
        
        //start เพิ่มข้อมูลลาไม่เกิน1วัน
        this.leave_data.amount = avg_minutes;
        this.leave_data.userId = localStorage.getItem(server.USER_ID);
        this.leave_data.departmentId = localStorage.getItem(server.DEPARTMENT_ID);
        this.leave_data.companyId = localStorage.getItem(server.COMPANYID);

        this.$showLoader();

        console.log("leave_data:", JSON.stringify(this.leave_data));
        let formData = new FormData();

        this.image_list.forEach((x, index) => {
          formData.append(`image`, x);
        });

        formData.append("images", "");
        formData.append("leaveType", this.leave_data.leaveTypeId.toString());
        formData.append("leaveTypeId", this.leave_data.leaveTypeId.toString());
        formData.append("approve_id_now", this.user_approve_all[0]);
        formData.append("startDate",this.leave_data.startDate.replace("+07:00", ""));
        formData.append("startHour",this.leave_start_hours);
        formData.append("endDate", this.leave_data.endDate.replace("+07:00", ""));
        formData.append("endHour", 0);
        formData.append("type_count",this.day_select);
        formData.append("description", this.leave_data.description);
        formData.append("userId", this.leave_data.userId);
        formData.append("departmentId", this.leave_data.departmentId);
        formData.append("companyId", this.leave_data.companyId);
        formData.append("amount", this.leave_data.amount);
        formData.append("lq_id_detail", this.leave_data.lq_id_detail);

        const res_h = await api.updateLeaveHistory(this.$route.params.id,formData);
        if (res_h.status == 200 || res_h.status == 201) {
          // console.log("res_h:", JSON.stringify(res_h.data));
          // this.user_approve_all.forEach((item) => {
          //   var data = {
          //     leave_his_id: res_h.data.module_id,
          //     approve_id: item,
          //   };
          //   api.addLeaveApproveDetail(data);
          // });
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Request Submitted",
            "You have submitted this request.",
            "text-h5 green--text text-center",
            "/my-time-off"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "บันทึกข้อมูลไม่สำเร็จ",
            "text-h5 red--text text-center"
          );
        }
        this.$hideLoader();
      }else if(this.day_select == "multiple"){
        let quota_check = 0;
        for (const [index, item] of period_data.entries()){

          let avg_minutes = 0;
              
          let startDateMany = new Date(item.leave_date_start);
          let endDateMany = new Date(item.leave_date_end);

          while (startDateMany <= endDateMany) {

              const dayOfWeekMany = startDateMany.getDay();

              // Check if the day is not Saturday (6) or Sunday (0)
              if (dayOfWeekMany !== 6 && dayOfWeekMany !== 0) {
                  var data_check = startDateMany.toISOString().substr(0, 10);
                  var res_date_check = await api.getLeaveHolidayByDate(data_check,Number(localStorage.getItem(server.COMPANYID)))
                  if(res_date_check.data.length == 0){
                    avg_minutes += 8 * 60;
                  }
              }

            startDateMany.setTime(startDateMany.getTime() + millisecondsInOneDay);
          }

          //start บวกขอบ ของวัน 
          if(period_data.length == 1){
            avg_minutes = (avg_minutes - (2*8*60)) + (Number(this.leave_start_hours) * 60) + (Number(this.leave_end_hours) * 60);
          }else if(period_data.length > 1){
            if(index == 0){
              avg_minutes = (avg_minutes - (1*8*60)) + (Number(this.leave_start_hours) * 60);
            }
            if(index == (period_data.length - 1)){
              avg_minutes = (avg_minutes - (1*8*60)) + (Number(this.leave_end_hours) * 60);
            }
          }else{
            return;
          }
            
          //end บวกขอบ ของวัน 
          //start เช็คโคต้าวันลา
          var quota_amt = 0; //โควต้าวันลาเป็นนาที

          this.dataAll.forEach((item) => {
            if (item.id == period_data[index].leave_quota_id) {
              this.leave_data.lq_id = period_data[index].leave_quota_id;
              quota_amt = item.balance * 480;
            }
          });
                
          console.log(quota_amt)
          console.log(avg_minutes)

          if (avg_minutes > quota_amt) {
            quota_check = 1;
          }
          //end เช็คโคต้าวันลา

          // console.log("avg_minutes: " + avg_minutes)
          sum_avg_minutes += avg_minutes;
          if(index > 0){
            leave_quota_list += `,${item.leave_quota_id}`
          }else{
            leave_quota_list += `${item.leave_quota_id}`
          }
            
          avg_minutes_list.push({
            leave_quota_id: item.leave_quota_id,
            avg_minutes: avg_minutes
          })
            
        }

        // alert(quota_check)
        if(quota_check == 1){
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "ลาเกิน",
            "คุณลาเกินวันลาที่มี",
            "text-h5 red--text text-center"
          );
          return;
        }

        //start เพิ่มข้อมูลลาเกิน1วัน
        this.leave_data.amount = sum_avg_minutes;
        this.leave_data.userId = localStorage.getItem(server.USER_ID);
        this.leave_data.departmentId = localStorage.getItem(server.DEPARTMENT_ID);
        this.leave_data.companyId = localStorage.getItem(server.COMPANYID);

        this.$showLoader();

        // console.log("leave_data:", JSON.stringify(this.leave_data));

        let formData = new FormData();

        this.image_list.forEach((x, index) => {
          formData.append(`image`, x);
        });

        formData.append("images", "");
        formData.append("leaveType", this.leave_data.leaveTypeId.toString());
        formData.append("leaveTypeId", this.leave_data.leaveTypeId.toString());
        formData.append("approve_id_now", this.user_approve_all[0]);
        formData.append("startDate",this.leave_data.startDate.replace("+07:00", ""));
        formData.append("startHour",this.leave_start_hours);
        formData.append("endDate", this.leave_data.endDate.replace("+07:00", ""));
        formData.append("endHour",this.leave_end_hours);
        formData.append("type_count",this.day_select);
        formData.append("description", this.leave_data.description);
        formData.append("userId", this.leave_data.userId);
        formData.append("departmentId", this.leave_data.departmentId);
        formData.append("companyId", this.leave_data.companyId);
        formData.append("amount", this.leave_data.amount);
        formData.append("lq_id_detail", leave_quota_list);

        let lq_id_v = "";
        avg_minutes_list.forEach((item,index)=>{
          if(index == 0){
            lq_id_v += `{ "lq_id_v": ${item.leave_quota_id}, "avg_minutes": ${item.avg_minutes}}`
          }else{
            lq_id_v += `|{ "lq_id_v": ${item.leave_quota_id}, "avg_minutes": ${item.avg_minutes}}`
          }  
        })

        formData.append("lq_id_v", lq_id_v);
        
        const res_h = await api.updateLeaveHistory(this.$route.params.id,formData);
        if (res_h.status == 200 || res_h.status == 201) {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Request Submitted",
            "You have submitted this request.",
            "text-h5 green--text text-center",
            "/my-time-off"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "บันทึกข้อมูลไม่สำเร็จ",
            "text-h5 red--text text-center"
          );
        }
        this.$hideLoader();

      }else{
        return;
      }

    },

    async onCancelSave() {
      this.dialogCancel = false;

      this.$showLoader();
      console.log("this.delete_lq_id")
      console.log(this.delete_lq_id)

      if(this.delete_lq_id.length > 0){
        //ลบ leave history
        const res_dlq = await api.delete_leaveH(this.$route.params.id);
        console.log("res_dlq.data")
        console.log(res_dlq.data)

        if (res_dlq.data == 1) {
            await api.delete_leaveQHD(this.$route.params.id)
            await api.delete_leaveAD(this.$route.params.id)
            this.delete_lq_id.forEach( async(item,index) => {
              var update_check = 0;
              var use_leave_c = 0;
              this.dataAll.forEach((items) => {
                if (items.id == item.old_lq_id) {
                  use_leave_c = items.use_leave;
                }
              });

              console.log("use_leave : " + use_leave_c)
              this.update_delete_req.use_leave = use_leave_c;

              const res_ulq = await api.updateQuota(item.old_lq_id,this.update_delete_req);
              if (res_ulq.status == 200 || res_ulq.status == 201) {
                update_check = 1;
              }
              if(update_check == 1){
                if(index == (this.delete_lq_id.length -1)){
                  this.$hideLoader();
                  this.$store.state.global_push_dialog = true;
                  this.setupAlertDialogPush(
                    true,
                    "Request Canceled",
                    "You have canceled this request.",
                    "text-h5 green--text text-center",
                    "/my-time-off"
                  );
                  return;
                }

              }else{
                this.$hideLoader();
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                  true,
                  "Information Saved",
                  "บันทึกข้อมูลไม่สำเร็จ",
                  "text-h5 red--text text-center"
                );
                return;
              }
            })
        }else{
          this.$hideLoader();
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "บันทึกข้อมูลไม่สำเร็จ",
            "text-h5 red--text text-center"
          );
          return;
        }
      }
      // this.$hideLoader();
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    saveItemConfirm() {
      this.dialogSave = true;
    },
    cancelReauest() {
      this.dialogCancel = true;
    },
    closeSave() {
      this.dialogSave = false;
    },
    closeCancel() {
      this.dialogCancel = false;
    },
    deleteItemConfirm(index) {
      this.indeximage = index;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.indeximage = null;
      this.dialogDelete = false;
    },
    async removeimage(index) {
      this.preview_list.splice(this.indeximage, 1);
      this.image_list.splice(this.indeximage, 1);

      this.dialogDelete = false;
    },
    quotaListConvert: function(H) {
      var d = 0;
      var h = 0;
      var m = 0;
      var tmp1 = parseFloat(H * 480);
      var tmp_final;

      var tmp_d = tmp1 / 480;

      d = Math.floor(tmp_d);
      h = Math.floor((tmp_d - d) * 8);
      m = Math.floor(((tmp_d - d) * 8 - h) * 60);

      tmp_final = `${d} Days ${h} Hour ${m} Minute`;

      return tmp_final;
    },

    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          if (input.files[index].type.split("/")[0] === "image") {
            this.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.image_list);
    },
  },
};
